<template>
  <div class="index">
    <div class="nav1">
      <div class="tit1">公司库管理</div>
    </div>
    <div class="nav2">
      <div class="myForm">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item size="small" label="行业：">
<!--            <el-input v-model="searchForm.industry"></el-input>-->
            <el-select v-model="searchForm.industry" clearable  filterable placeholder="请选择">
              <el-option
                  v-for="item in industrys"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value">
                <span style="float: right">{{ item.label }}</span>
                <span style="float: left; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="small" label="公司：">
            <el-input minlength="2" v-model="searchForm.name">公司</el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="searchOnSubmit" size="small" type="primary" icon="el-icon-search">查询</el-button>
          </el-form-item>
<!--          <el-form-item>-->
<!--            <el-button @click="AddSaishi" size="small" type="primary" icon="el-icon-plus">添加公司</el-button>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button disabled @click="ImportGongsi" size="small" type="primary" icon="el-icon-upload">导入公司</el-button>
          </el-form-item>
          <el-form-item>
              <el-button disabled @click="DelGongsiConfirm" size="small" type="primary" icon="el-icon-del">按行业删除</el-button>
          </el-form-item>
          <el-form-item>
            <el-tag>内存数量:{{companySize}}</el-tag>
          </el-form-item>
          <!-- <el-form-item>
            <el-button @click="CompanyLoad" size="small" type="primary" :icon="memory_table_icon">加载到内存</el-button>
          </el-form-item> -->
        </el-form>
      </div>

      <div class="myTable">
        <vxe-table :loading="loading" align="center" :data="tableData" ref="myTable" :import-config="importConfig">
          <vxe-table-column field="name" width="300" title="企业名称"></vxe-table-column>
          <vxe-table-column field="name0" width="250" title="曾用名"></vxe-table-column>
          <vxe-table-column field="engname" width="250" title="英文名"></vxe-table-column>
          <vxe-table-column field="scale" width="100" title="规模"></vxe-table-column>
          <vxe-table-column field="industry" width="80" title="行业分类"></vxe-table-column>
          <vxe-table-column field="intro"  width="200" title="企业简介"></vxe-table-column>
          <vxe-table-column field="scope"  width="200" title="经营范围"></vxe-table-column>
          <vxe-table-column title="操作" width="180">
            <template slot-scope="scope">
              <div class="flex">
                <el-button disabled size="small" type="text" @click="toEdit(scope.row)">修改</el-button>
                <el-button disabled size="small" type="text" @click="toDel(scope.row)">删除</el-button>
              </div>
            </template>
          </vxe-table-column>
        </vxe-table>
        <el-pagination class="fenye" @size-change="this.handleSizeChange" @current-change="this.handleCurrentChange"
          :current-page="this.biaobaiqiangPage" :page-size="10" :page-sizes="[10, 15, 20, 30]"
          layout="total,sizes, prev, pager, next, jumper" :total="this.total"></el-pagination>
      </div>
    </div>
    <!-- 添加/修改广告 -->
    <el-dialog title="添加/修改" :visible.sync="addDialogVisible" width="700px" :before-close="addHandleClose">
      <div class="myAddForm">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" class="demo-addForm">
          <el-row>
            <el-col :span="20">
              <el-form-item label="名称">
                <el-input size="small" readonly="readonly" disabled="true" placeholder="请输入名称" v-model="addForm.name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="曾用名">
                <el-input size="small" placeholder="请输入曾用名" v-model="addForm.name0"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="英文名">
                <el-input size="small"  placeholder="请输入英文名" v-model="addForm.engname"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="规模">
                <el-input size="small" placeholder="请输入规模" v-model="addForm.scale"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="行业分类">
                <el-input size="small"  readonly="readonly" disabled="true" placeholder="请输入行业分类" v-model="addForm.industry"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="企业简介">
                <el-input size="small" placeholder="请输入企业简介" type="textarea" autosize  v-model="addForm.intro"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="经营范围">
                <el-input size="small" placeholder="请输入经营范围" type="textarea" autosize  v-model="addForm.scope"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="20">
              <el-form-item>
                <el-button size="small" type="primary" @click="AddOnSubmit('addForm')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <input type="file" name="companyLogo" id="file0" class="displayN" multiple="multiple" @change="companyLogo($event)"
      ref="fileInputList" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState([
      "tabShopIndex",
      "biaobaiqiangPage",
      "biaobaiqiangPageSize",
      "xiaoshoubiaoPage",
      "xiaoshoubiaoPageSize"
    ])
  },
  watch: {
    tabShopIndex: function () {
      this.activeName = this.tabShopIndex;
      console.log(this.activeName);
      this.getData();
    },
    biaobaiqiangPage: function (page) {
      this.$store.commit("biaobaiqiangPage", page);
      this.getData();
    },
    biaobaiqiangPageSize: function (pageSize) {
      this.$store.commit("biaobaiqiangPageSize", pageSize);
      this.getData();
    },
    xiaoshoubiaoPage: function (page) {
      this.$store.commit("xiaoshoubiaoPage", page);
      this.getxsData();
    },
    xiaoshoubiaoPageSize: function (pageSize) {
      this.$store.commit("xiaoshoubiaoPageSize", pageSize);
      this.getxsData();
    }
  },
  data() {
    return {
      is_status: -1,
      loading: false,
      activeName: "1",
      searchForm: {
        challenges_id: "",
        name:"",
        industry:"",
      },
      tableData: [],
      total: 0,
      addDialogVisible: false,
      addForm: {
        is_status: '',
        category: '',
        name:"",
        url_type:"",
        url:"",
        pic:"",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      imgStatus: "",
      imgFile: "",
      id: "",
      xsaddDialogVisible: false,
      xstableData: [],
      xssearchForm: {
        time: ""
      },
      xstotal: 0,
      SFlist: [],
      CSlist: [],
      imgIndex: 0,
      Userlist: [],
      isAdd: false,
      SSlist: [],
      importConfig:{
        remote: true,
        importMethod: this.importMethod ,
        types:['xlsx'],
        modes: ['insert']
      },
      industrys:[],
      memory_table_icon:"el-icon-data-line",
      companySize:0
    };
  },
  created() {
    this.$store.commit("biaobaiqiangPage", 1);
    this.$store.commit("biaobaiqiangPageSize", 10);
    this.getData();
    this.getIndustrys();
    this.CompanySize();
  },
  methods: {
    importMethod({file,options }){
      console.log(file)
      console.log(options)
      // 处理表单
      const formBody = new FormData()
      formBody.append('file', file)
      formBody.append('scene', "import_company")
      // 上传文件
      return this.$api.import_excel(formBody).then(res => {
        console.log(res)
        if (res.data.result == 1) {
          this.getData()
          this.CompanySize()
          this.$message({
            type: 'success',
            message: '导入成功'
          });
        } else {
          this.$message({
            type: 'error',
            message: '导入失败'
          });
        }
      }).catch(() => {
        this.$message({ content: '导入失败，请检查数据是否正确！', status: 'error' })
      })
    },
    async getIndustrys() {
      const res = await this.$api.gsindustrys({
        page: this.biaobaiqiangPage,
        pagesize: this.biaobaiqiangPageSize,
      });
      this.industrys = res.list ? res.list : [];
    },
    async getData() {
      this.loading = true;
      const res = await this.$api.gslist({
        page: this.biaobaiqiangPage,
        pagesize: this.biaobaiqiangPageSize,
        name:this.searchForm.name,
        industry:this.searchForm.industry
      });
      console.log(res);
      this.tableData = res.list ? res.list : [];
      this.total = res.total
      this.tableData.forEach(ele => {
        ele.myis_status = ele.is_status == 1 ? '开启' : '关闭';
        ele.mycategory_id = ele.category_id == 1 ? '猎头端首页' : ele.category_id == 2 ? '求职端首页' : ele.category_id == 3 ? '求职端岗位详情页' : ele.category_id == 4 ? '猎头端导航页1' : ele.category_id == 5 ? '猎头端导航页2' : '猎头端导航页3';
        ele.myurl_types = ele.url_types == 1 ? '内部链接' : '外部链接';
      })
      this.loading = false;
      this.getIndustrys();
    },
    changeSF() {
      this.getCSData()
    },
    xssearchOnSubmit() {
      this.getxsData();
    },
    addEditGuanliyuan(row) {
      this.id = row.id;
      this.addForm.user_name = row.store_username;
      this.addForm.status = row.store_userstatus.toString();
      this.addDialogVisible = true;
    },
    seeXiaoshou(row) {
      this.id = row.id;
      this.xsaddDialogVisible = true;
    },
    xsaddHandleClose() {
      this.xsaddDialogVisible = false;
    },
    // 开关（显示/隐藏）
    async changeKG2(row) {
      console.log(row);
      const res = await this.$api.store_setflag({
        id: row.id,
        flag: row.myFlagship == true ? "1" : "0"
      });
      console.log(res);
      if (res.data.result == 1) {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        this.getData();
      }
    },
    async changeKG(row) {
      console.log(row);
      const res = await this.$api.store_setstatus({
        id: row.id,
        status: row.myIs_status == true ? "1" : "0"
      });
      if (res.data.result == 1) {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        this.addDialogVisible = false;
        this.getData();
      }
    },
    toEdit(row) {
      console.log(row)
      this.id = row.id
      this.addForm = { ...row }
      this.$set(this.addForm,'url_type',row.url_types)
      this.$set(this.addForm,'category',row.category_id)
      this.$set(this.addForm,'url',row.url_address)
      // this.addForm.url_type = row.url_types;
      // this.addForm.category = row.category_id;
      // this.addForm.url = row.url_address;
      this.isAdd = false;
      // this.addForm.is_status = row.is_status.toString()
      this.addDialogVisible = true
    },
    async toDel(row) {
      const res = await this.$api.del_gs({
        id: row.id
      })
      if (res.result == 1) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
        this.CompanySize();
      }
    },
    tabsHandleClick(tab) {
      console.log(tab.index);
      this.$store.commit("tabShopIndex", (Number(tab.index) + 1).toString());
    },
    searchOnSubmit() {
      console.log(this.searchForm);
      if(this.searchForm.name!='' && this.searchForm.name.length<2){
        this.$message({
          message: "公司最少2个字",
          type: "error"
        });
        return;
      }
      // this.biaobaiqiangPage=1;
      this.$store.commit("biaobaiqiangPage", 1);
      this.getData();
    },
    AddSaishi() {
      this.isAdd = true;
      for (const key in this.addForm) {
        if (key != 'details_pic') {
          this.$set(this.addForm, key, "");
        }
      }
      this.addDialogVisible = true;
    },
    ImportGongsi(){
      this.$refs.myTable.importData()
    },
    DelGongsiConfirm(){
      this.$confirm('确认删除行业'+this.searchForm.industry, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.DelGongsi()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async DelGongsi(){
      if(this.searchForm.industry==""){
        this.$message.error("请输入行业");
      }
      const res = await this.$api.del_gs2({
        industry: this.searchForm.industry,
      })
      if (res.result == 1) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
        this.CompanySize();
      }
    },
    async CompanySize(){
      const res = await this.$api.memorytable({
        table_name:"company",
        action:"size",
      })
      this.companySize=res.size;
    },
    async CompanyLoad(){
      if(this.memory_table_loading==true){
        return;
      }
      this.memory_table_loading=true;
      this.memory_table_icon="el-icon-loading";
      const res = await this.$api.memorytable({
        table_name:"company",
        action:"load",
      })
      this.companySize=res.size;
      this.memory_table_icon="el-icon-data-line";
      this.memory_table_loading=false;
    },
    addHandleClose() {
      this.addDialogVisible = false;
    },
    tabEdit(row) {
      console.log(row);
      this.id = row.id;
      row.is_show = row.status == "0" ? "隐藏" : "显示";
      this.addForm = { ...row };
      this.addDialogVisible = true;
    },
    async tabDel(row) {
      console.log(row);
      const res = await this.$api.categoryDel(row.id);
      if (res) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        setTimeout(() => {
          this.getData();
        }, 500);
      } else {
        this.$message.error(res.msg);
      }
    },
    AddOnSubmit(formName) {
      console.log(this.addForm);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.isAdd) {
            // 新增
            const res = await this.$api.add_gs({
              ...this.addForm,
            });
            if (res.result == 1) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.addDialogVisible = false;
              this.getData();
            } else {
              this.$message({
                message: res.msg,
              });
            }
          } else {
            // 修改
            const res = await this.$api.edit_gs({
              ...this.addForm,
              id:this.id
            });
            if (res.result == 1) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.addDialogVisible = false;
              this.getData();
              this.companySize();
            }else {
              this.$message({
                message: res.msg,
              });
            }
          }

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除图片
    delImg(val, i = 0) {
      if (val == "details_pic") {
        if (this.addForm.details_pic[i].name != '') {
          this.addForm.details_pic.splice(i, 1)
        }
      } else if (val == "tb") {
        this.$set(this.addForm,'pic','')
      }
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$store.commit("biaobaiqiangPageSize", val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$store.commit("biaobaiqiangPage", val);
    },
    xshandleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$store.commit("xiaoshoubiaoPageSize", val);
    },
    xshandleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$store.commit("xiaoshoubiaoPage", val);
    },
    // 上传图片
    companyList(val, i = 0) {
      this.imgIndex = i;
      this.imgStatus = val;
      this.$refs.fileInputList.click();
    },
    //将文件转为blob类型
    readFileAsBuffer(file) {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.readAsDataURL(file);
        reader.onload = function () {
          const base64File = reader.result.replace(
            /^data:\w+\/\w+;base64,/,
            ""
          );
          resolve(new window.OSS.Buffer(base64File, "base64"));
        };
      });
    },
    async companyLogo(event) {
      const that = this;
      var file = event.target.files[0];
      var fileSize = file.size; //文件大小
      var filetType = file.type; //文件类型
      //创建文件读取对象
      // console.log(file);
      if (fileSize <= 10240 * 1024) {
        if (
          filetType == "image/png" ||
          filetType == "image/jpeg" ||
          filetType == "image/gif"
        ) {
          var file_re = await this.readFileAsBuffer(file);
          // console.log(this.imgFile);
          const res = await this.$api.aliyun_osssts()
          let client = new window.OSS.Wrapper({
            secure:true,
            region:res.region, //oss地址
            accessKeyId: res.accessKeyId, //ak
            accessKeySecret: res.accessKeySecret, //secret
            stsToken: res.stsToken,
            bucket:res.bucket, //oss名字
          });
          var imgtype = file.type.substr(6, 4);
          var store = `${new Date().getTime()}.${imgtype}`;
          console.log(store);
          client.put(store, file_re).then(() => {
            //这个结果就是url
            var a = client.signatureUrl(store);
            if (a.match('^http://')) {
              a = a.replace("http://", "https://")
            }
            this.oss_imgurl = a;
            console.log(a);
            if(this.imgStatus == 'tb'){
              this.$set(this.addForm,'pic',a)
            }
          });
          that.$refs.fileInputList.value = "";
        } else {
          this.$message.error("图片格式不正确");
        }
      } else {
        this.$message.error("图片大小不正确");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.index {}

.nav1 {
  margin: 0 -18px;
  background-color: #fff;
  padding: 20px 32px 0 40px;

  .tit1 {
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 20px;
  }
}

.myForm {
  ::v-deep  .el-form-item__label {
    font-size: 12px;
  }

  ::v-deep  .el-form-item {
    margin-right: 30px;
    margin-bottom: 0;
    vertical-align: middle;
  }
}

.tit1 {
  margin-top: 10px;
}

.myTable {
  .xiala {
    padding: 10px 20px;

    .item {
      font-size: 12px;
      display: flex;
    }
  }

  margin-top: 10px;

  ::v-deep  .vxe-table--render-default .vxe-body--column {
    line-height: 14px;
    vertical-align: middle;
  }

  ::v-deep  .vxe-cell--label {
    font-size: 12px;
  }

  ::v-deep  .vxe-cell--title {
    font-size: 12px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.nav2 {
  margin: 18px 0;
  background: #fff;
  border-radius: 6px;
  padding: 24px;

  .myForm {
    ::v-deep  .el-form-item__label {
      font-size: 12px;
    }

    ::v-deep  .el-form-item {
      margin-right: 30px;
      margin-bottom: 0;
      vertical-align: middle;
    }
  }

  .tit1 {
    margin-top: 10px;
  }

  .myTable {
    margin-top: 10px;

    ::v-deep  .vxe-table--render-default .vxe-body--column {
      line-height: 14px;
      vertical-align: middle;
    }

    ::v-deep  .vxe-cell--label {
      font-size: 12px;
    }

    ::v-deep  .vxe-cell--title {
      font-size: 12px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.myAddForm {
  ::v-deep  .el-select {
    width: 100%;
  }

  ::v-deep  .el-form-item__label {
    font-size: 12px;
    width: 130px !important;
  }

  ::v-deep  .el-form-item__content {
    margin-left: 130px !important;
  }

  ::v-deep  .el-radio__label {
    font-size: 12px;
  }

  ::v-deep  .el-button {
    width: 100%;
  }

}

.fenye {
  margin-top: 16px;
}

.displayN {
  display: none;
}

.myImg {
  position: relative;
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-right: 12px;

  .closeBtn {
    position: absolute;
    top: -6px;
    right: -4px;
    width: 20px;
    height: 20px;

    .el-button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ::v-deep  .image-slot {
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fafafa;
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-icon-picture-outline {
      font-size: 20px;
    }
  }

  .myImg {
    position: relative;
    width: 60px;
    height: 60px;
    display: inline-block;
    margin-right: 12px;

    .closeBtn {
      position: absolute;
      top: -6px;
      right: -4px;
      width: 20px;
      height: 20px;

      .el-button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    ::v-deep  .image-slot {
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #fafafa;
      width: 58px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .el-icon-picture-outline {
        font-size: 20px;
      }
    }
  }
}
</style>
